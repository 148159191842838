<template lang="pug">
  layout-access
    account-register(:action='action')
</template>

<script>
import LayoutAccess from '@components/layouts/Access'
import AccountRegister from '@views/container/account/Register'

export default {
  name: 'register',
  components: {
    LayoutAccess,
    AccountRegister,
  },
  props: {
    action: String,
  },
}
</script>
