<template lang="pug">
  #wrapper.l-access.l-wrapper
    #wrapper-inner.l-wrapper-inner
      #content.l-content
        slot
</template>

<script>
export default {
  name: 'l-access',
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.l-access {
  background: url($wc-wave) no-repeat center center;
  background-size: cover;
  .l-wrapper-inner {
    background: url($wc-wave-second) no-repeat center center;
    background-size: cover;
  }
}
</style>
